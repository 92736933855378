<script setup>
import FhSection from "../includes/FhSection.vue";
import FhButton from "./FhButton.vue";
import { getDataRefs } from "../util/vue";
import { ref, computed } from "vue";
import FhVideo from "@/components/FhVideo.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  },
  isVideo: {
    type: Boolean,
    default: false
  }
});

const {
  title = ref(null),
  description = ref(null),
  imageSrc = ref(null),
  imageAltText = ref(null),
  caption = ref(null),
  linkUrl = ref(null),
  linkTarget = ref(null),
  linkText = ref(null),
  linkAriaLabel = ref(null),
  gaTagging,
  horizontalPositioning = ref(null),
  verticalPositioning = ref(null)
} = getDataRefs(props.data);

const aspect = ref(null);

const aspectRatio = computed(() => {
  if (!aspect.value) return "0";
  else return `${aspect.value * 100}%`;
});

const getImageAspect = () => {
  const tempImage = document.createElement("img");
  tempImage.onload = function () {
    aspect.value = parseInt(this.height) / parseInt(this.width);
  };
  tempImage.src = imageSrc.value;
};

getImageAspect();
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <FhSection
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="fh-figure relative flex">
      <figure :class="[title || description || linkText ? 'inset-0 h-full w-full object-cover' : 'flex flex-col gap-f3', { absolute: !isVideo }]">
        <FhVideo
          v-if="isVideo"
          :src="imageSrc"
          autoplay
          muted
          loop
          :controls="false"
          :class="title || description || linkText ? 'h-full w-full object-fill' : 'h-auto max-w-full'"
          @load="getImageAspect"
        ></FhVideo>
        <img
          v-else
          :src="imageSrc"
          :alt="imageAltText"
          :class="title || description || linkText ? 'h-full w-full object-cover' : 'h-auto max-w-full'"
          @load="getImageAspect"
        />
        <figcaption v-if="caption && !(title || description || linkText)" class="px-4 text-f-sm-base text-neutral-50" v-html="caption"></figcaption>
      </figure>

      <div
        v-if="title || description || linkText"
        :class="['z-10 flex min-h-[min(400px,60vh)] w-full py-f10 px-f5 content', verticalPositioning ?? '', horizontalPositioning ?? '']"
      >
        <div :class="['flex flex-col gap-1', verticalPositioning.replace('items', 'justify') ?? '', horizontalPositioning.replace('justify', 'items') ?? '']">
          <h2
            v-if="title"
            :class="[
              'w-full max-w-[675px] whitespace-pre-line font-serif text-f-4xl-8xl font-light leading-[1.25] text-white',
              description ? 'mb-f3' : 'mb-f5'
            ]"
            v-html="title"
          ></h2>
          <p
            v-if="description"
            class="mb-f5 w-full max-w-[600px] whitespace-pre-line text-f-base-2xl font-light leading-[1.25] text-white"
            v-html="description"
          ></p>
          <FhButton v-if="linkText" :inverse="true" :href="linkUrl" :target="linkTarget" :aria-label="linkAriaLabel" :data-ga="gaTagging" color="primary"
            >{{ linkText }}
          </FhButton>
        </div>
      </div>
    </div>
  </FhSection>
</template>

<style scoped lang="pcss">
.fh-figure {
  --padding: v-bind(aspectRatio);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  & * {
    grid-area: 1 / 2 / 1 / 1;
  }
  &::before {
    content: "";
    pointer-events: none;
    padding-top: var(--padding);
    align-self: start;
    grid-area: 1 / 2 / 1 / 1;
  }
}
@media (max-width: 767px) {
  .object-fill {
    object-fit: inherit !important;
  }
}
:deep(.fh_video) {
  @apply h-full w-full object-fill;
}
</style>
