<script setup>
import { ref, computed, watch } from "vue";
import { useTimeAgo, useElementSize } from "@vueuse/core";
import { useViewportSizes } from "@/composables";
import { MEASUREMENT_UNITS } from "@/constants/measurements";
import { TRADEPROGRAM, HOWTOBUY, LOGIN } from "@/constants/routes";
import { useFeaturesStore } from "@/stores/FeaturesStore";
import formatPrice from "@/util/formatPrice.js";
import FhAddToCart from "@/components/FhAddToCart.vue";
//import FhBreadcrumb from "@/components/FhBreadcrumb.vue";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhImage from "@/components/FhImage.vue";
import FhVideo from "@/components/FhVideo.vue";
import FhProductDetailImageGallery from "@/components/FhProductDetailImageGallery.vue";
import FhProductDetailMobileImageGallery from "@/components/FhProductDetailMobileImageGallery.vue";
import FhProductDetailSpecList from "@/components/FhProductDetailSpecList.vue";
import FhProductOptions from "@/components/FhProductOptions.vue";
import FhProductTags from "@/components/FhProductTags.vue";
import FhTabs from "@/components/FhTabs.vue";
import FhAlert from "@/components/FhAlert.vue";
import FhProductCareCard from "@/components/FhProductCareCard.vue";
import FhProductDetailDownloadList from "@/components/FhProductDetailDownloadList.vue";
import { event_viewItem, event_addItemToCart } from "@/util/googleAnalytics";
import FhAddToShoppingListFormModal from "./FhAddToShoppingListFormModal.vue";
import FhShoppingListForm from "@/components/FhShoppingListForm.vue";
import FhWallpaperCalculator from "@/components/FhWallpaperCalculator.vue";
import { useUpdateItemMutation, useDeleteShoppingItemMutation, useCreateShoppingListMutation } from "@/api/shoppingListApi";
import { useNotificationStore } from "@/stores/NotificationStore";
import { MEDIA_TYPES } from "@/constants/media";
const { isLoading: addToMutationLoading, mutate: addToShoppingListMutation } = useUpdateItemMutation();
const { isLoading: removeItemToMutationLoading, mutate: removeItemToShoppingListMutation } = useDeleteShoppingItemMutation();
const { isLoading: createShoppingListMutationLoading, mutate: createSHoppingListMutation } = useCreateShoppingListMutation();

const notificationStore = useNotificationStore();
const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  isAuthenticated: {
    type: Boolean,
    default: false
  },
  hideCtas: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  isUnavailable: {
    type: Boolean,
    default: false
  },
  canAddToCart: {
    type: Boolean,
    default: false
  },
  activeSku: {
    type: String,
    default: () => undefined
  },
  priceMarkup: {
    type: Object,
    default: () => {}
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  breadcrumb: {
    type: Object,
    default: () => {}
  }
});

const { isSm } = useViewportSizes();
const showAddToShoppingListForm = ref(false);
const showForm = ref(false);
const shoppingListToModify = ref(null);

// Updated to use the breadcrumb.crumbs prop to accomodate for Art Studio items + other products
const categoryItems = computed(() => {
  return props.breadcrumb?.crumbs.map((crumb) => ({
    name: crumb.name,
    url: crumb.url
  }));
});

const featuresStore = useFeaturesStore();
const pdfTearSheetsEnabled = computed(() => featuresStore.getFeatureValue("pdfTearSheetsEnabled"));

const activeSkuNumber = ref(props.activeSku ?? props.product.skus[0].skuNumber);
const activeSku = computed(() => (!props.isUnavailable ? props.product.skus.find((sku) => sku.skuNumber === activeSkuNumber.value) : null));

const markup = ref(props.priceMarkup?.markup);
const showPriceMarkup = ref(props.priceMarkup?.showPriceMarkup || false);
const showCADPricing = ref(props.priceMarkup?.showCADPricing || false);
const noSkuTags = computed(() => {
  return (activeSku.value.tags && !activeSku.value.tags.length) || !activeSku.value.tags;
});

// encode product url before appending to Login string
const returnURL = computed(() => "/product/" + activeSkuNumber.value);
const encodedReturnURL = computed(() => encodeURIComponent(returnURL.value));

const loginReturnURL = computed(() => LOGIN + "?returnurl=" + encodedReturnURL.value);

// function to replace URL in location bar when the SKU changes
function updateURL() {
  window.history.replaceState(null, null, returnURL.value);
}

const MEDIA_PREVIEW_COUNT = 6;
const mediaPreview = computed(() => activeSku.value.media.slice(0, MEDIA_PREVIEW_COUNT));
const mediaAll = computed(() => activeSku.value.media);
const mediaGalleryActive = ref(false);
const mediaGalleryInitialIndex = ref(0);
const mediaHasVideo = computed(() => activeSku?.value?.media.some((media) => media.type === MEDIA_TYPES.VIDEO));
function openMediaGalleryToIndex(index) {
  mediaGalleryInitialIndex.value = index || 0;
  mediaGalleryActive.value = true;
}

const mainMediaContainer = ref([]);
function scrollToMainMediaElem(index) {
  let elem = mainMediaContainer.value.children[index];
  if (elem && elem instanceof HTMLElement) {
    elem.scrollIntoView({ block: "start", behavior: "smooth" });
  }
}

const canAddToCart = computed(() => props.isAuthenticated && props.canAddToCart);

const hideCtas = computed(() => props.isAuthenticated || props.hideCtas);

const isUnavailable = computed(() => props.isUnavailable);

const showPrice = computed(() => activeSku.value.price && props.isAuthenticated);

const availabilityUpdatedTimeAgo = useTimeAgo(() => activeSku.value.availabilityLastUpdatedDate);

const showCutover = computed(() => !!activeSku.value.hasBeenCutoverFrom);

const tearsheet = computed(() => activeSku.value?.tearSheetUrl || null);

// TODO: handle imperial/metric toggle?
const activeOverallDimensionsString = computed(() => activeSku.value.dimensionsImperial);

const activeCoreSpecs = computed(() => {
  let sku = activeSku.value;
  let specs = [];
  if (sku.dimensionsImperial) specs.push({ label: "Overall Dimensions", value: sku.dimensionsImperial });
  if (sku.skuNumber) specs.push({ label: "Item Number", value: sku.skuNumber });
  if (sku.collection) specs.push({ label: "Collection", value: sku.collection });
  if (sku.colors) specs.push({ label: "Colors", value: sku.colors });
  if (sku.materials) specs.push({ label: "Materials", value: sku.materials });
  if (sku.hasPerformanceFabric) specs.push({ label: "Fabric", value: "Performance" });
  if (sku.weightPounds) specs.push({ label: "Weight", value: sku.weightPounds, unit: MEASUREMENT_UNITS.POUND });
  if (sku.volumeCubicFeet) specs.push({ label: "Volume", value: sku.volumeCubicFeet, unit: MEASUREMENT_UNITS.CUBIC_FOOT });
  return specs;
});

const activeFullSpecs = computed(() => {
  let specs = [];
  if (activeCoreSpecs.value.length) specs.push(activeCoreSpecs.value);
  if (activeSku.value.specs && activeSku.value.specs.length) specs.push(...activeSku.value.specs);
  return specs;
});

const fullDetailsSection = ref(null);
const detailTabsSelectedIndex = ref(0);

// Compute the Box Count and Single Box Specs
const boxCount = computed(() => {
  if (activeSku.value.boxes) {
    return activeSku.value.boxes.length;
  } else {
    return null;
  }
});

const activeSingleBoxWeight = computed(() => {
  let specs = [];
  if (activeSku.value.boxes && activeSku.value.boxes.length === 1)
    specs.push([
      {
        label: "Box Dimensions",
        value: activeSku.value.boxes[0].widthInches + '" w x ' + activeSku.value.boxes[0].depthInches + '" d x ' + activeSku.value.boxes[0].heightInches + '" h'
      },
      { label: "Box Weight", value: activeSku.value.boxes[0].weightPounds, unit: MEASUREMENT_UNITS.POUND }
    ]);
  return specs;
});

// Scroll to the "Full Details" section at the bottom of the PDP if it exists (tablet/desktop only),
// otherwise, select the "Details" tab (mobile)
function showFullDetails() {
  if (fullDetailsSection.value && fullDetailsSection.value instanceof HTMLElement) {
    fullDetailsSection.value.scrollIntoView({ block: "start", behavior: "smooth" });
  } else {
    detailTabsSelectedIndex.value = 1;
  }
}

// Manage the showing/hiding of a long list of full specs (tablet/desktop only), if longer than the max container height
const fullSpecsContainer = ref(null);
const fullSpecs = ref(null);
const { height: fullSpecsContainerHeight } = useElementSize(fullSpecsContainer);
const { height: fullSpecsHeight } = useElementSize(fullSpecs);
const doFullSpecsOverflow = computed(() => fullSpecsContainerHeight.value < fullSpecsHeight.value);
const areFullSpecsExpanded = ref(false);
watch(activeSku, () => {
  areFullSpecsExpanded.value = false;
  updateURL();
});

const showProductCare = computed(() => activeSku.value.primaryMaterialCareInfo !== null);
const showDownloads = computed(() => activeSku.value.downloads.length > 0);

/* wallpaper calc */
const qty = ref(1);
const handle_Rolls = (valor) => {
  if (valor === 0) return (qty.value = 1);
  qty.value = valor;
};

const hasWallpaperItems = computed(() => {
  return props.product?.category?.name.toLowerCase().includes("wallpaper") || false;
});

const hasSwatch = computed(() => {
  if (
    hasWallpaperItems.value === true &&
    activeSku.value?.wallpaperDimensions?.[0]?.dimensionsImperial?.width === 12 &&
    activeSku.value?.wallpaperDimensions?.[0]?.dimensionsImperial?.height === 8
  ) {
    return true;
  }
  return false;
});
const wallpaperDimensions = computed(() => activeSku.value?.wallpaperDimensions?.[0] || {});
/* END wallpaper calc */

const handle_AddToCart = (skuInfo) => {
  const skuAdded = props.product.skus.find((sku) => sku.skuNumber === skuInfo.sku);
  event_addItemToCart({ ...skuAdded, quantity: skuInfo.qty, name: props.product.name, has_video: mediaHasVideo.value }, categoryItems.value);
};

event_viewItem({ ...activeSku.value, name: props.product.name, has_video: mediaHasVideo.value }, categoryItems.value);

function handalClick(val) {
  shoppingListToModify.value = {
    name: props.product.name,
    skuNumber: activeSku.value.skuNumber,
    thumbUrl: activeSku.value.media[0].thumbUrl,
    price: activeSku.value.price,
    skuName: activeSku.value.name
  };
  showAddToShoppingListForm.value = val;
}

function addtolistModal(val) {
  shoppingListToModify.value = {
    name: props.product.name,
    skuNumber: activeSku.value.skuNumber,
    thumbUrl: activeSku.value.media[0].thumbUrl,
    price: activeSku.value.price,
    skuName: activeSku.value.name
  };
  if (val == true) {
    showAddToShoppingListForm.value = false;
    showForm.value = val;
  } else {
    showAddToShoppingListForm.value = true;
    showForm.value = false;
  }
  showForm.value = val;
}
const addToShoppingList = (shoppingListDetails) => {
  const shoppinglist = {
    title: shoppingListDetails.title,
    description: shoppingListDetails.description,
    pricingVisibility: shoppingListDetails.pricingVisibility,
    ShoppingListItems: [{ SkuNumber: shoppingListDetails.skuNumber, Quantity: 1 }]
  };
  createSHoppingListMutation(shoppinglist, {
    onSuccess: () => notificationStore.notifySuccess("Your shopping list has been created."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not create that shopping List."),
    onSettled: () => {
      showForm.value = false;
    }
  });
};

const handaleheartclickevent = (item) => {
  const data = {
    skuNumber: item.formData.skuNumber,
    Quantity: 1,
    ShoppingListId: item.item.id
  };
  addToShoppingListMutation(data, {
    onSuccess: () => notificationStore.notifySuccess("Item added to shopping list."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not create that shopping List.")
  });
};
const handleheartfilledevent = (item) => {
  const data = {
    skuNumber: item.formData.skuNumber,
    ShoppingListId: item.item.id
  };
  const shoppingListId = data.ShoppingListId;
  const targetSkuNumber = data.skuNumber;
  const targetShoppingList = item.shopping.find((item) => item.id === shoppingListId);
  const matchingProduct = targetShoppingList.products.find((product) => product.skuNumber === targetSkuNumber);
  const product = { ShoppingListID: item.item.id, id: matchingProduct.id, quantity: matchingProduct.quantity };
  removeItemToShoppingListMutation(product, {
    onSuccess: () => notificationStore.notifySuccess("Item removed from shopping list."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not remove that shopping List Item.")
  });
};
</script>

<template>
  <div v-if="isUnavailable" class="my-f5 flex flex-col items-center justify-center">
    <FhAlert icon="AlertCircle" class="w-full max-w-lg">
      <div>This item is no longer available.</div>
    </FhAlert>
  </div>
  <template v-else>
    <div v-if="showCutover">
      <FhAlert icon="InformationCircle" class="my-f5">
        <div>
          This product has replaced item number {{ activeSku.hasBeenCutoverFrom }}. Rest assured. You're shopping the same product with a new item number.
        </div>
      </FhAlert>
    </div>
    <div class="mb-f15 md:grid md:grid-cols-[auto_minmax(0,445px)] md:items-start md:gap-x-f5" :class="{ 'mt-f5': !props.isAuthenticated }">
      <div class="flex flex-col md:top-f4 md:col-start-2 md:flex-row md:flex-wrap">
        <FhProductTags :tags="activeSku.tags" class="mb-f2 md:-order-1" />
        <div v-if="!props.isAuthenticated && noSkuTags" class="w-full md:min-h-[2rem]"></div>
        <!-- eyebrowText NAME -->
        <div v-if="activeSku?.eyebrowText" class="text-body-sm font-medium md:w-full mb-f1 text-neutral-50">{{ activeSku.eyebrowText }}</div>
        <!-- PRODUCT NAME -->
        <h1 class="mb-f1 text-f-3xl-5xl md:w-full">{{ props.product.name }}</h1>
        <!-- SKU NAME AND NUMBER -->
        <div class="text-body mb-f1 text-neutral-50 md:w-full">
          <span v-if="activeSku.name">{{ activeSku.name }}&nbsp;&bull;&nbsp;</span>
          <span>{{ activeSku.skuNumber }}</span>
        </div>
        <!-- IN STOCK -->
        <div v-if="activeSku.inStock" class="mb-f1 md:w-full">In Stock <FhIcon name="LightningBolt" class="relative -top-px" /></div>
        <!-- SHARE AND FAVORITE BUTTONS -->
        <div class="mb-f3 flex gap-f3 md:relative md:-top-2 md:-order-1 md:ml-auto" :class="{ 'md:min-h-[2rem]': !props.isAuthenticated }">
          <button v-if="props.isAuthenticated" aria-label="Add to List" class="text-2xl" @click="handalClick(true)"><FhIcon name="OutlineHeart" /></button>
        </div>
        <!-- SPACER -->
        <div class="mb-f3 h-0 w-full"></div>
        <!-- PRICE -->
        <div v-if="showPrice" class="mb-f5 flex items-center gap-f3 md:w-full">
          <div class="text-f-3xl-5xl" :class="{ 'text-brand-red': activeSku.isDiscounted }">
            <span v-if="(props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin)">CAD</span>{{ formatPrice(activeSku.price) }}
          </div>
          <div
            v-if="activeSku.isDiscounted && activeSku.originalPrice"
            class="text-f-3xl-5xl text-neutral-50 line-through"
            :class="{ 'hidden md:block': activeSku.secondaryPrice }"
          >
            <span v-if="(props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin)">CAD</span
            >{{ formatPrice(activeSku.originalPrice) }}
          </div>
          <div v-if="activeSku.priceLabel" class="w-px self-stretch bg-neutral-30">&nbsp;</div>
          <div v-if="activeSku.priceLabel" class="text-neutral-50 before:w-px">
            {{ activeSku.priceLabel }}
          </div>
          <div v-if="activeSku.secondaryPrice" class="w-px self-stretch bg-neutral-30">&nbsp;</div>
          <div v-if="activeSku.secondaryPrice" class="text-neutral-50 before:w-px">
            <span v-if="(props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin)">CAD</span
            >{{ formatPrice(activeSku.secondaryPrice) }}
            <span v-if="activeSku.secondaryPriceName">{{ activeSku.secondaryPriceName }}</span>
          </div>
        </div>
        <!-- MOBILE IMAGES -->
        <FhProductDetailMobileImageGallery :media="mediaAll" class="mb-f5" />
        <!-- PRODUCT OPTIONS -->
        <FhProductOptions v-if="props.product.options?.length" v-model:active-sku="activeSkuNumber" :options="props.product.options" class="mb-f5 w-full" />
        <!-- NON-AUTHENTICATED CTA BUTTONS -->
        <div v-if="!hideCtas" class="flex w-full flex-col">
          <div class="mb-f2 flex w-full flex-col gap-f2 md:flex-row">
            <FhButton :href="HOWTOBUY" color="primary" class="w-full whitespace-nowrap">How to Buy</FhButton>
          </div>
          <div class="mb-f5 flex w-full flex-col gap-f2 md:flex-row">
            <FhButton :href="TRADEPROGRAM" class="w-full whitespace-nowrap">Join Trade Program</FhButton>
            <FhButton :href="loginReturnURL" class="w-full whitespace-nowrap">Log In</FhButton>
          </div>
        </div>
        <!-- ADD TO CART + AVAILABILITY INFO -->
        <div
          v-if="props.isAuthenticated"
          class="relative left-1/2 mb-f5 flex w-screen -translate-x-1/2 flex-col gap-f2 border border-solid border-neutral-30 bg-neutral-10 p-f4 md:w-full md:p-f2"
        >
          <FhAddToCart
            v-if="canAddToCart"
            :sku-number="activeSku.skuNumber"
            :price="activeSku.price"
            :category="categoryItems"
            :quantity="qty"
            @update:add-to-cart="handle_AddToCart"
            @update:quantity="handle_Rolls"
          />
          <div v-if="hasWallpaperItems && !hasSwatch">
            <FhWallpaperCalculator
              display-system="IMPERIAL"
              :dimensions-imperial="wallpaperDimensions.dimensionsImperial"
              :dimensions-metric="wallpaperDimensions.dimensionsMetric"
              :quantity="qty"
              :open-panel="false"
              @update-wallpaper-pdp="handle_Rolls"
            />
          </div>
          <div v-if="activeSku.availabilityDescription">
            <!-- eslint-disable-next-line -->
            <div v-if="activeSku.availabilityDescription" v-html="activeSku.availabilityDescription"></div>
            <div v-if="availabilityUpdatedTimeAgo" class="text-neutral-50">As of {{ availabilityUpdatedTimeAgo }}</div>
          </div>
        </div>

        <!-- NON-AUTHENTICATED DETAILS -->
        <div v-if="!props.isAuthenticated" class="w-full">
          <div v-if="activeSku.description" class="mb-f5 text-f-sm-lg">{{ activeSku.description }}</div>
          <div v-if="activeOverallDimensionsString" class="mb-f3 flex w-full flex-col md:flex-row md:justify-between">
            <span class="mb-f0.5 md:mb-0">Overall Dimensions</span>
            <span class="text-neutral-50">{{ activeOverallDimensionsString }}</span>
          </div>
          <div class="flex flex-col items-start gap-f2">
            <FhButton
              v-if="tearsheet"
              variant="text"
              :href="tearsheet"
              :class="{ 'hidden lg:inline-block': !pdfTearSheetsEnabled, 'inline-block': pdfTearSheetsEnabled }"
              target="_blank"
              ><FhIcon name="TextDocument" />Download Tear Sheet</FhButton
            >
            <FhProductCareCard v-if="showProductCare" :data="activeSku.primaryMaterialCareInfo"></FhProductCareCard>
          </div>
        </div>

        <!-- AUTHENTICATED DETAILS (TABS) -->
        <FhTabs
          v-if="props.isAuthenticated"
          :selected-index="detailTabsSelectedIndex"
          align="left"
          underline
          compact
          class="w-full"
          @change="(index) => (detailTabsSelectedIndex = index)"
        >
          <template #trigger-overview>Overview</template>
          <template #content-overview>
            <div class="flex flex-col gap-f5">
              <div v-if="activeSku.description" class="text-f-sm-lg">{{ activeSku.description }}</div>
              <div>
                <div v-if="activeOverallDimensionsString" class="mb-f0.5 flex w-full flex-col md:flex-row md:justify-between">
                  <span class="mb-f0.5 md:mb-0">Overall Dimensions</span>
                  <span class="text-neutral-50">{{ activeOverallDimensionsString }}</span>
                </div>
                <FhButton variant="link" @click="showFullDetails">Full Details &amp; Specifications</FhButton>
              </div>
              <div class="flex flex-col items-start gap-f2">
                <FhButton
                  v-if="tearsheet"
                  variant="text"
                  :href="tearsheet"
                  :class="{ 'hidden lg:inline-block': !pdfTearSheetsEnabled, 'inline-block': pdfTearSheetsEnabled }"
                  target="_blank"
                  ><FhIcon name="TextDocument" />Download Tear Sheet</FhButton
                >
                <FhButton v-if="activeSku.assemblyInstructionsUrl" variant="text" :href="activeSku.assemblyInstructionsUrl" class="inline-block"
                  ><FhIcon name="TextDocument" />Download Assembly Instructions</FhButton
                >
                <FhProductCareCard v-if="showProductCare" :data="activeSku.primaryMaterialCareInfo"></FhProductCareCard>
              </div>
            </div>
          </template>
          <template #trigger-details>Details</template>
          <template #content-details>
            <FhProductDetailSpecList :specs="isSm && areFullSpecsExpanded ? activeFullSpecs : activeCoreSpecs" />
            <FhButton v-if="isSm && activeFullSpecs.length > 1" variant="text" class="mt-f5" @click="areFullSpecsExpanded = !areFullSpecsExpanded">
              Show {{ areFullSpecsExpanded ? "Fewer" : "All" }} Details
              <FhIcon name="DownChevron" :class="{ 'rotate-180': areFullSpecsExpanded }" />
            </FhButton>
            <FhButton v-if="!isSm" variant="link" class="mt-f5" @click="showFullDetails">Full Details &amp; Specifications</FhButton>
            <!-- PROP 65 WARNING -->
            <div v-if="activeSku.prop65WarningHtml" class="flex gap-f2 bg-neutral-10 p-f2 mt-8">
              <div class="text-body-xl"><FhIcon name="AlertTriangle" aria-hidden="true" /></div>
              <!-- eslint-disable-next-line -->
              <div v-html="activeSku.prop65WarningHtml" class="text-sm mt-f0.5"></div>
            </div>
            <section v-if="isSm && showDownloads" class="mt-f5">
              <h3 class="mr-f1 inline-block">Downloads</h3>
              <div class="items-start gap-f2">
                <FhProductDetailDownloadList :downloads="activeSku.downloads" />
              </div>
            </section>
          </template>
          <template #trigger-shipping>Shipping</template>
          <template #content-shipping>
            <div class="flex flex-col gap-f5">
              <div v-if="boxCount === null || boxCount === 0">
                <span class="text-neutral-50">Please contact your sales support specialist for shipping dimensions and weights.</span>
              </div>
              <div v-else-if="boxCount === 1">
                <FhProductDetailSpecList :specs="activeSingleBoxWeight" />
              </div>
              <div v-else>
                <table>
                  <thead>
                    <tr>
                      <th class="px-f3 py-1"></th>
                      <th class="tableHeader">Width</th>
                      <th class="tableHeader">Depth</th>
                      <th class="tableHeader">Height</th>
                      <th class="tableHeader">Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in activeSku.boxes" :key="index">
                      <td class="py-f1 pr-f3">Box {{ index + 1 }}</td>
                      <td class="tableData">{{ item.widthInches }}"</td>
                      <td class="tableData">{{ item.depthInches }}"</td>
                      <td class="tableData">{{ item.heightInches }}"</td>
                      <td class="tableData">{{ item.weightPounds }} lb</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </FhTabs>
      </div>

      <!-- TABLET/DESKTOP IMAGES -->
      <div v-if="!isSm" class="hidden md:col-start-1 md:row-start-1 md:block lg:grid lg:grid-cols-[6rem_1fr] lg:gap-x-f5">
        <!-- THUMBS -->
        <div class="hidden lg:block">
          <div class="sticky top-f4 flex flex-col gap-f1">
            <button
              v-for="(media, i) in mediaPreview"
              :key="media.thumbUrl"
              class="group relative block"
              title="Scroll to image"
              @click="scrollToMainMediaElem(i)"
            >
              <FhImage :src="media.thumbUrl" :alt="`${props.product.name} thumbnail`" square border class="group-hover:border-neutral-70" />
              <div v-if="media.type === MEDIA_TYPES.VIDEO" class="absolute top-0 left-0 h-full w-full flex justify-center items-center">
                <FhIcon name="VideoPlay" class="h-[46px] w-[46px]" />
              </div>
            </button>
            <FhButton
              v-if="mediaAll.length - mediaPreview.length > 0"
              variant="link"
              size="small"
              class="mt-f2"
              title="Open gallery"
              @click="openMediaGalleryToIndex(0)"
            >
              View All ({{ mediaAll.length }})
            </FhButton>
          </div>
        </div>
        <!-- MAIN IMAGE GRID -->
        <div ref="mainMediaContainer" class="grid grid-cols-2 gap-f2">
          <button
            v-for="(media, i) in mediaPreview"
            :key="media.largeUrl"
            class="cursor-zoom-in scroll-mt-f4"
            :class="{ 'col-span-2': i % 3 === 0 }"
            title="Open gallery"
            @click="openMediaGalleryToIndex(i)"
          >
            <FhImage v-if="media.type === MEDIA_TYPES.IMAGE" :src="media.largeUrl" :alt="media.altText" :square="i === 0 || i % 3 !== 0" border />
            <FhVideo v-if="media.type === MEDIA_TYPES.VIDEO" :src="media.videoUrl" :autoplay="true" :loop="true">
              <template #overlay="{ videoToggle, isPlaying }">
                <div class="absolute top-0 left-0 h-full w-full"></div>
                <button class="absolute bottom-2 right-2 flex h-16 w-16" @click.stop="videoToggle">
                  <FhIcon v-if="!isPlaying" name="VideoPlay" class="h-full w-full" />
                  <FhIcon v-else name="VideoPause" class="h-full w-full" />
                </button>
              </template>
            </FhVideo>
          </button>
        </div>
      </div>
      <FhProductDetailImageGallery
        :is-active="mediaGalleryActive"
        :media="mediaAll"
        :product-name="props.product.name"
        :sku-name="activeSku.name"
        :initial-media-index="mediaGalleryInitialIndex"
        :is-authenticated="props.isAuthenticated"
        @close="mediaGalleryActive = false"
      />
    </div>

    <div v-if="$slots.banner" class="my-f15">
      <slot name="banner"></slot>
    </div>

    <!-- FULL DETAILS SECTION (AUTHENTICATED, TABLET/DESKTOP ONLY) -->
    <section v-if="!isSm && props.isAuthenticated" ref="fullDetailsSection" class="mb-f15">
      <h2 class="mb-f2 text-f-xl-3xl">Full Details &amp; Specifications</h2>
      <section class="grid grid-cols-2 py-f5">
        <h3 class="text-body-lg">Overall Dimensions</h3>
        <div>{{ activeOverallDimensionsString }}</div>
      </section>
      <section class="grid grid-cols-2 border-t border-solid border-neutral-20 py-f5">
        <h3 class="text-body-lg">Product Details</h3>
        <div>
          <div
            ref="fullSpecsContainer"
            :class="{
              'max-h-96 overflow-hidden': !areFullSpecsExpanded,
              'relative after:absolute after:bottom-0 after:left-0 after:right-0 after:h-20 after:bg-gradient-to-b after:from-transparent after:to-white':
                doFullSpecsOverflow && !areFullSpecsExpanded
            }"
          >
            <div ref="fullSpecs">
              <FhProductDetailSpecList :specs="activeFullSpecs" />
            </div>
          </div>
          <FhButton
            v-if="doFullSpecsOverflow || areFullSpecsExpanded"
            variant="text"
            class="float-right"
            :class="areFullSpecsExpanded ? 'mt-f5' : 'mt-f2'"
            @click="areFullSpecsExpanded = !areFullSpecsExpanded"
          >
            View {{ areFullSpecsExpanded ? "Fewer" : "All" }} Details
            <FhIcon name="DownChevron" :class="{ 'rotate-180': areFullSpecsExpanded }" />
          </FhButton>
        </div>
      </section>
      <section class="grid grid-cols-2 border-t border-solid border-neutral-20 py-f5">
        <h3 class="text-body-lg">Shipping Details</h3>
        <div v-if="boxCount === null || boxCount === 0">
          <span class="text-neutral-50">Please contact your sales support specialist for shipping dimensions and weights.</span>
        </div>
        <div v-else-if="boxCount === 1">
          <FhProductDetailSpecList :specs="activeSingleBoxWeight" />
        </div>
        <div v-else>
          <table>
            <thead>
              <tr>
                <th class="px-f3 py-1"></th>
                <th class="tableHeader">Width</th>
                <th class="tableHeader">Depth</th>
                <th class="tableHeader">Height</th>
                <th class="tableHeader">Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in activeSku.boxes" :key="index">
                <td class="py-f1 pr-f3">Box {{ index + 1 }}</td>
                <td class="tableData">{{ item.widthInches }}"</td>
                <td class="tableData">{{ item.depthInches }}"</td>
                <td class="tableData">{{ item.heightInches }}"</td>
                <td class="tableData">{{ item.weightPounds }} lb</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section v-if="showProductCare || showDownloads" class="grid grid-cols-2 border-t border-solid border-neutral-20 py-f5">
        <h3 class="text-body-lg">Downloads</h3>
        <div class="flex flex-col items-start gap-f2">
          <FhButton
            v-if="tearsheet"
            variant="text"
            :href="tearsheet"
            :class="{ 'hidden lg:inline-block': !pdfTearSheetsEnabled, 'inline-block': pdfTearSheetsEnabled }"
            target="_blank"
            ><FhIcon name="TextDocument" />Download Tear Sheet</FhButton
          >
          <FhButton v-if="activeSku.assemblyInstructionsUrl" variant="text" :href="activeSku.assemblyInstructionsUrl" class="inline-block"
            ><FhIcon name="TextDocument" />Download Assembly Instructions</FhButton
          >
          <FhProductCareCard v-if="showProductCare" :data="activeSku.primaryMaterialCareInfo"></FhProductCareCard>
          <FhProductDetailDownloadList :downloads="activeSku.downloads" />
        </div>
      </section>
    </section>
    <!-- <FhModal v-model:is-open="productCareModalIsOpen" :data="productCareModalData" /> -->
  </template>
  <FhAddToShoppingListFormModal
    v-if="showAddToShoppingListForm"
    :is-open="showAddToShoppingListForm"
    :shopping-details="shoppingListToModify"
    :is-loading="addToMutationLoading || removeItemToMutationLoading"
    :is-admin="props.isAdmin"
    :is-c-a-d-price="props.isCADPrice"
    :is-customer-guest="props.isCustomerGuest"
    @addtolist="addtolistModal"
    @heart-icon-clicked="handaleheartclickevent"
    @heart-icon-filled="handleheartfilledevent"
    @update:is-open="handalClick"
  ></FhAddToShoppingListFormModal>
  <FhShoppingListForm
    v-if="showForm"
    :is-admin="props.isAdmin"
    :is-open="showForm"
    :is-creating="createShoppingListMutationLoading"
    :shopping-details="shoppingListToModify"
    :edit-mode="false"
    :is-plp-pdp="true"
    :markup="markup"
    :show-price-markup="showPriceMarkup"
    :show-c-a-d-pricing="showCADPricing"
    :is-c-a-d-price="props.isCADPrice"
    :is-customer-guest="props.isCustomerGuest"
    @create="addToShoppingList"
    @update:is-open="addtolistModal"
  ></FhShoppingListForm>
</template>

<style lang="pcss" scoped>
.tableHeader {
  @apply px-f2 py-f1 text-left;
}

.tableData {
  @apply px-f2 py-f1 text-left text-neutral-50;
}
:deep(.fh_video) {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
}
</style>
